import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/interfaces/user';
import { AuthService, UserService } from 'src/app/services';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { HomeComponent } from '../home/home.component';
import { environment } from 'src/environments/environment';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: User;
  @Output() backButtonClicked = new EventEmitter<boolean>();
  @Output() LogoutButtonClicked = new EventEmitter<boolean>();

  constructor(
    private _userService: UserService,
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _spinner: NgxSpinnerService,
    private _dialog: MatDialog,

  ) {}

  ngOnInit() { }

  getProfile() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);
    this._userService.profile().subscribe((response: ApiResponse) => {
      const profile: User = response.data;
      this.profile = profile;
      setTimeout(() => {
          // this._spinner.hide();
          this._dialog.closeAll();
      }, DefaultValue.loadingTimeOut);
    });
  }

  logout() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);

    this._authService.logOut().subscribe(_ => {
      setTimeout(() => {
        localStorage.removeItem(environment.authIdentifierKey);
        localStorage.removeItem(environment.authUser);
        localStorage.clear();
        // this._spinner.hide();
        this._dialog.closeAll();
        this.LogoutButtonClicked.emit(true);
      }, DefaultValue.loadingTimeOut + 2000);
    },(error)  => {
      this._snackBar.open("Oops.. !" + error.error.message, 'Close');
      // this._spinner.hide();
      this._dialog.closeAll();
    })

  }

  back() {
    this.backButtonClicked.emit(true)
    // this.HomeComponent.accountMenu = false;
  }

}
