<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="box_dialog clean_dialog">
    <div class="box_dialog_content">
      <h1>Your Password
        <br/>Changed!</h1>
        <svg xmlns="http://www.w3.org/2000/svg" width="234.87" height="234.87" viewBox="0 0 234.87 234.87">
          <g id="Group_20351" data-name="Group 20351" transform="translate(-7185.995 -6242.682)">
            <circle id="Ellipse_10285" data-name="Ellipse 10285" cx="117.435" cy="117.435" r="117.435" transform="translate(7185.995 6242.682)" fill="#ffdaad"/>
            <path id="Path_63045" data-name="Path 63045" d="M7258.927,6370.6l25.71,25.71,63.3-63.3" fill="none" stroke="#ac1f1f" stroke-linecap="round" stroke-linejoin="round" stroke-width="13.057"/>
          </g>
        </svg>
      <p>Your password has been updated. Please log in with your email and the new password.</p>
      <button (click)="login()">Login</button>
    </div>
  </div>
  <img src="../../../assets/frame_small.png" alt="" class="fram_box">
</div>
