<div class="popup_collect" [@dialogEnter] [@dialogExit]>
  <div class="box_popup active_moodal prize_modal">

    <div class="center_flex" *ngIf="data.data.type === prizeType.smallPrize">
      <span class="inline_span">You have collected <b>1x Ang Pao</b> <img src="../../../assets/small_angpao.png" alt=""> </span>
      <h2>{{data.data.name }}</h2>
      <span>{{data.data.description }}</span>
      <img class="larger_img" src="../../../assets/{{data.data.image}}" alt="">
    </div>
    <div class="center_flex" *ngIf="data.data.type === prizeType.greeting">
      <span>You have collected <b>1x Ang Pao</b></span>
      <img class="greeting_img" src="../../../assets/{{ data.data.image }}" alt="">
      <h6>{{ data.data.name }}</h6>
    </div>

    <img src="../../../assets/frame_greet.png" alt="" class="bg_frame greeting_bg" *ngIf="data.data.type === prizeType.greeting">
    <img src="../../../assets/frame_hotel_offer.png" alt="" class="bg_frame hotel_offers_bg" *ngIf="data.data.type === prizeType.smallPrize">
    <img src="../../../assets/frame_small_prize.png" alt="" class="bg_frame" *ngIf="data.data.type === prizeType.bigPrize">


    <div class="center_flex_pos special_size" *ngIf="data.data.type === prizeType.bigPrize">
      <!-- <img class="claim_el el_1" src="../../../assets/calim_el_1.png" alt="">
      <img class="claim_el el_2" src="../../../assets/claim_el_2.png" alt="">
      <img class="claim_el el_3" src="../../../assets/claim_el_3.png" alt=""> -->
      <img class="prize_image" src="../../../assets/{{data.data.image}}" alt="">
      <div class="prize_info_claim">
        <h5>You have collected 1x Ang Pao and won a</h5>
        <h4>{{data.data.name }}</h4>
      </div>
    </div>

    <button (click)="Redeem()" *ngIf="data.data.type === prizeType.bigPrize">Redeem</button>
    <button mat-dialog-close *ngIf="data.data.type === prizeType.greeting || data.data.type === prizeType.smallPrize">Continue</button>
  </div>
</div>
