import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-update-password-submited',
  templateUrl: './update-password-submited.component.html',
  styleUrls: ['./update-password-submited.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class UpdatePasswordSubmitedComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<UpdatePasswordSubmitedComponent>,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  login() {
    this._dialogRef.close();
    this._dialog.open(LoginComponent, {data: {}})
  }

}
