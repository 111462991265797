import { environment } from "src/environments/environment";


export class Helper {
    public static generateWhatsAppMessageLink(phoneNumber: string, message: any): string {
        const textMessage = Helper.objectToQueryString(message);
        return environment.whatsAppUniversalLink.concat(phoneNumber,'/?', textMessage);
    }

    public static generateWazeLink(ll: string,): string {
        return environment.wazeUniversalLink.concat('ul?ll='+ ll +'&navigate=yes');
    }

    public static objectToQueryString(dataParams: any): string {
        const params = new URLSearchParams();
        for (const key in dataParams) {
          if(dataParams[key]) {
            params.set(key, dataParams[key]);
          }
        }

        return params.toString();
      }
}