<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="inner_modal login_modal">
    <div class="box_dialog ">
      <div class="box_dialog_content box_forgot">
        <h1>CREATE<br/>NEW PASSWORD</h1>
        <div class="form">
          <form [formGroup]="dataForm">
            <input type="password" placeholder="New Password" formControlName="new_password">
            <input type="password" placeholder="Confirm Password" formControlName="confirm_password">
            <small class="help-block text-red" *ngIf="isPasswordMatchError">Confirm new password not match</small>
          </form>
        </div>
        <br/><br/>
        <button (click)="updatePassword()" [ngClass]="{'disable_button': !dataForm.valid}" [disabled]="!dataForm.valid">Update Password</button>
      </div>
    </div>
  </div>
  <img src="../../../assets/login_frame.png" class="login_frame" alt="">
</div>
