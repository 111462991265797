import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginComponent } from '../login/login.component';
import { ForgotSucceedComponent } from '../forgot/forgot-succeed/forgot-succeed.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/services';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ForgotComponent implements OnInit {

  dataForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private _authService: AuthService,
    private _spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<ForgotComponent>
  ) {}

  ngOnInit() {
  }

  reset() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);

    this._authService.forgotPassword(this.dataForm.value).subscribe((response: ApiResponse) => {
      setTimeout(() => {
      this._dialog.closeAll();
      // this._spinner.hide();
        this._dialogRef.close();
        const dialog = this._dialog.open(ForgotSucceedComponent, {
          data: response.data,
        });
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this._snackBar.open("Oops.. !" + error.error.message, 'Close');
      // this._spinner.hide();
      this._dialog.closeAll();
    });
  }

  login() {
    this._dialogRef.close();
    const dialog = this._dialog.open(LoginComponent, {
      width: '100vw',
    });
  }

  close() {
    this._dialogRef.close();
  }

}
