<div *ngIf="!isLoading">
  <div class="navigation" [ngsReveal]="{origin: 'top', scale: 1, distance: '10px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
    <div class="inner_nav">
      <div class="logo" (click)="scroll(home)">
        <img src="../../../assets/logo.png" alt="">
      </div>
      <div class="menu">
        <svg width="22" (click)="showMenu = true" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.75 5.5H19.25M2.75 11H19.25M2.75 16.5H19.25" stroke="white" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>

  <div class="flex_container" #home>
    <div class="baged" [ngsReveal]="{scale: 2, distance: '40px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
      <img src="../../../assets/badge.png" alt="">
    </div>

    <div class="h_scroll" [ngsReveal]="{origin: 'right', distance: '100px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
      <div class="scroll_area">
        <!-- <div class="collect_items collect1 collected"  *ngIf="todayAngpao">
          <img src="../../../assets/collected.png" alt="">
        </div> -->
        <div class="collect_items collect1" (click)="bigPow()" *ngIf="!isAngpau1Collected">
          <img src="../../../assets/collect.png" alt="">
        </div>
        <div class="collect_items collect2" (click)="smallPow()" *ngIf="!isAngpau2Collected">
          <img src="../../../assets/collect.png" alt="">
        </div>
        <div class="moving_area" [ngStyle]="{'margin-left' : '-'+this.step+'px'}">
          <img src="../../../assets/land.png">
        </div>
      </div>
    </div>
    <div class="divider_box" [ngsReveal]="{origin: 'bottom', distance: '220px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }"></div>
    <div class="bottom_content" >
      <div class="people">
        <div class="collect_items collected"  *ngIf="isAngpau1Collected && isAngpau2Collected && totalCollected <= 34">
          <img src="../../../assets/collected.png" alt="">
        </div>
        <div class="collect_items collected"  *ngIf="isAngpau1Collected && isAngpau2Collected && totalCollected > 34">
          <img src="../../../assets/finish_message.png" alt=""> 
        </div>
        <!-- <img class="walking" src="../../../assets/walking.gif" *ngIf="isMoving"> -->
        <!-- <img [ngClass]="isMoving ? 'walking' : null" src="../../../assets/man.png" *ngIf="!isAngpau1Collected || !isAngpau2Collected || !isMoving"> -->
        <div class="walkin_anim" [ngClass]="{'play': isMoving, 'pre-play': preplay }" *ngIf="totalCollected < 36"></div>
        <img class="man_finish" src="../../../assets/man_finish.png" *ngIf="totalCollected > 35">
      </div>

      <div class="info_box">
        <div class="content">
          <div class="pill">
            <img src="../../../assets/ang_icon.png" alt="">
            <h2 *ngIf="isLogedIn">{{ totalCollected > 9 ? '':'0' }}{{totalCollected > 36 ? 36:totalCollected }}<span>/{{totalAngpau}}</span></h2>
            <h2 *ngIf="!isLogedIn">00<span>/{{totalAngpau}}</span></h2>
          </div>
          <!-- <h3>Collect Ang Pau and stand a chance to win total prizes worth up to <span>RM6,888!</span></h3> -->
        </div>

        <img src="../../../assets/imageprize.png" alt="" class="img_prize_all">
        <!-- <img src="../../../assets/frame-angpau.png" alt=""> -->
        <!-- <img src="../../../assets/frame-angpau-no-cloud.png" alt="" style="width: 90%;">
        <img src="../../../assets/cloud-gold-left.png" class="animate cloud-image" alt="">
        <img src="../../../assets/cloud-gold-left.png" class="move-right cloud-left-image" alt=""> -->
        <!-- <img src="../../../assets/cloud-gold-right.png" class="move-right cloud-right-image" alt=""> -->
      </div>

      <div class="scrolldown">
        <img src="../../../assets/scroll.png" class="animate">
      </div>

    </div>

    <div class="prize_information" #guide>
      <img class="mobile_view" src="../../../assets/Information.png" alt="">
      <img class="desktop_view" src="../../../assets/info_desktop.png" alt="">
    </div>

    <div class="scrolldown">
      <img src="../../../assets/scroll.png" class="animate">
    </div>

    <div class="slidercontainer openhouse_section" id="openhouse" #open_house>
      <app-open-house></app-open-house>
    </div>


    <div class="slidercontainer offers_section" id="hotels" #hotels>
      <app-hotel-offer></app-hotel-offer>
    </div>


    <button (click)="scroll(home)" class="back_top cursor-pointer"><img src="../../../assets/top.png"></button>
  </div>

  <div class="popup_collect menu_fullscreen" *ngIf="showMenu">
    <div class="box_popup" style="background-image: url(../../../assets/popup_frame.png);" [ngClass]="showMenu ? 'active_moodal' : null">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <div class="logo" style="width: 50%;height: auto;margin: 8px;">
          <img src="../../../assets/logo.png" alt="">
        </div>
        <div class="account_menu" *ngIf="accountMenu">
          <app-profile [profile]="profile" (backButtonClicked)="accountMenu = false" (LogoutButtonClicked)="logOutButtonClicke()"></app-profile>
        </div>
        <div *ngIf="!accountMenu" class="menu_list">
          <button (click)="scroll(home)" style="margin: 1px; width: 100%;" class="active">Collect Ang Pao</button>
          <button (click)="scroll(guide)" style="margin: 1px; width: 100%;">Guide to Win</button>
          <button (click)="scroll(open_house)" style="margin: 1px; width: 100%;">Open House</button>
          <button (click)="scroll(hotels)" style="margin: 1px; width: 100%;">Hotel Offers</button>
          <!-- <button (click)="register()" *ngIf="!isLogedIn" style="margin: 1px;width: 100%;">Register</button> -->
          <!-- <button (click)="login()" *ngIf="!isLogedIn" style="margin: 1px;width: 100%;">Login</button> -->
          <button (click)="showAccount()" *ngIf="isLogedIn" style="margin: 1px;width: 100%;" class="menu_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="55.863" height="63.536" viewBox="0 0 55.863 63.536">
              <g id="Group_22092" data-name="Group 22092" transform="translate(910.416 -1687.475)">
                <path id="Path_66015" data-name="Path 66015" d="M-909.042,1751.011a2.4,2.4,0,0,1-1.373-2.561,24.5,24.5,0,0,1,20.14-23.374,30.58,30.58,0,0,1,4.254-.357c2.521-.055,5.047-.091,7.566,0,9.566.353,16.569,4.89,21.152,13.21a23.637,23.637,0,0,1,2.745,10.58,2.327,2.327,0,0,1-1.371,2.5Z" fill="#fff"/>
                <path id="Path_66016" data-name="Path 66016" d="M-899.238,1704.227a16.78,16.78,0,0,1,16.783-16.752,16.778,16.778,0,0,1,16.722,16.75,16.781,16.781,0,0,1-16.783,16.754A16.784,16.784,0,0,1-899.238,1704.227Z" fill="#fff"/>
              </g>
            </svg>
            Account
          </button>

        </div>


        <button (click)="showMenu = false; accountMenu = false" style="margin-top: 16px;" class="close_top">
          <svg xmlns="http://www.w3.org/2000/svg" width="57.624" height="57.624" viewBox="0 0 57.624 57.624">
            <g id="Group_22086" data-name="Group 22086" transform="translate(284.896 -61.188)">
              <rect id="Rectangle_6617" data-name="Rectangle 6617" width="73.192" height="8.3" rx="4.15" transform="translate(-279.027 61.188) rotate(45)" fill="#fff"/>
              <rect id="Rectangle_6618" data-name="Rectangle 6618" width="73.192" height="8.3" rx="4.15" transform="translate(-284.896 112.943) rotate(-45)" fill="#fff"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>


