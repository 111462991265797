import { Component, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { PrizeClaimSubmitedComponent } from './prize-claim-submited/prize-claim-submited.component';
import { Reward } from 'src/app/interfaces/reward';
import { AngpauService, UserService } from 'src/app/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/utils';
import { User } from 'src/app/interfaces/user';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-prize-claim',
  templateUrl: './prize-claim.component.html',
  styleUrls: ['./prize-claim.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class PrizeClaimComponent implements OnInit {

  profile: User;
  dataForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    passport_number: new FormControl('', Validators.required),
    mailing_address: new FormControl('', Validators.required),
    postal_code: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
  })

  constructor(
    private _angpauService: AngpauService,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
    private _spinner: NgxSpinnerService,
    private _dialogRef: MatDialogRef<PrizeClaimComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Reward
  ) { }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this._userService.profile().subscribe((response: ApiResponse) => {
      const profile: User = response.data;
      this.dataForm.patchValue({
        name: profile.name,
        email: profile.username,
        phone: profile.phone,
        mailing_address: profile.address,
        passport_number: profile.passport_number,
        postal_code: profile.postal_code,
        city: profile.city,
        state: profile.state,
      })
    });
  }

  claim() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);

    const params = {
      reward_id: this.data.id,
      personal_info: this.dataForm.value
    }
    this._angpauService.claim(params).subscribe((response: ApiResponse) => {
      this._dialogRef.close();
      // this._spinner.hide();
      this._dialog.closeAll();

      this._dialog.open(PrizeClaimSubmitedComponent);
    }, (error) => {
      this._snackBar.open("Oops.. !" + error.error.message, 'Close');
      // this._spinner.hide();
      this._dialog.closeAll();

    });
  }

}
