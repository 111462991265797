
<!-- #A61F1F -->

<div class="loading_bar">
  <div class="inner_bar">
    <div class="progress_bar">
    </div>
    <p>Loading...</p>
  </div>
</div>
