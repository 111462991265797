<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="box_dialog clean_dialog">
    <div class="box_dialog_content">
      <img src="../../../assets/event_highlight_title.png" alt="" class="title_img">
      <!-- <div class="hightlight">
        <div class="hightlight_items" *ngFor="let item of data.info.highlights">
          <img [src]="item.image">
          <h4>{{ item.name }}</h4>
        </div>
      </div> -->
      <div class="highlight_container" >
        <img src="../../../../assets/Bukit Bintang.png" class="event-list" alt="" *ngIf="data.name === 'Bukit Bintang Sales Gallery'">
        <img src="../../../../assets/Desa Tebrau.png" class="event-list" alt="" *ngIf="data.name === 'Desa Tebrau Sales Gallery'">
        <img src="../../../../assets/Impian Hills.png" class="event-list" alt="" *ngIf="data.name === 'Impian Hills Sales Gallery'">
      </div>
      <div class="form rsvp-form">
       <form [formGroup]="dataForm">
          <input type="text" placeholder="First Name" formControlName="name">
          <input type="text" placeholder="Contact Number" formControlName="phone" autocomplete="off" allowNumberOnly>
          <input type="email" placeholder="Email" formControlName="email">

          <div class="inline_input">
            <label class="text">No. of Pax Attending</label>
            <select formControlName="qty">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>

          <label class="text">Which open house will you be attending ?</label>
          <label class="container small_margin" *ngFor="let item of data.info.event">{{ item.attending }}
            <input type="checkbox" name="loc" (change)="selectLocation($event)" [value]="item.name">
            <span class="checkmark"></span>
          </label>
       </form>

      </div>
      <button [ngClass]="{'disable_button': !dataForm.valid}" [disabled]="!dataForm.valid" (click)="submit()">Submit</button>
      <button class="clean_btn underline" mat-dialog-close>Back</button>
    </div>
  </div>
  <img src="../../../assets/frame_rsvp.png" alt="" class="fram_box rsvp_form">
</div>
