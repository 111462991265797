<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="inner_modal register_modal" >
    <div class="close_button" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="57.624" height="57.624" viewBox="0 0 57.624 57.624">
        <g id="Group_22085" data-name="Group 22085" transform="translate(-963.123 -61.188)">
          <rect id="Rectangle_6614" data-name="Rectangle 6614" width="73.192" height="8.3" rx="4.15" transform="translate(968.992 61.188) rotate(45)" fill="#fff"/>
          <rect id="Rectangle_6615" data-name="Rectangle 6615" width="73.192" height="8.3" rx="4.15" transform="translate(963.123 112.943) rotate(-45)" fill="#fff"/>
        </g>
      </svg>
    </div>
    <div class="box_dialog">
      <div class="box_dialog_content">
        <h1>Welcome!</h1>
        <div class="form">
          <form [formGroup]="dataForm">
            <input type="text" placeholder="Full Name (as per NRIC / Passport*)" formControlName="name">
            <input type="email" placeholder="Email Address*" formControlName="email">
            <small class="help-block text-red" *ngIf="dataForm.get('email')?.value !=''  &&  !dataForm.get('email')?.valid">Invalid email format: e.g. example@domain.com</small>
            <input type="text" placeholder="Contact Number*" formControlName="phone" prefix="+60 " mask="99999999999">
            <input type="password" placeholder="Password" formControlName="password" autocomplete="off">

            <select formControlName="state" placeholder="Select your state">
              <option class="light_option" value="" disabled selected>Select State</option>
              <option class="light_option" value="Johor">Johor</option>
              <option class="light_option" value="Kedah">Kedah</option>
              <option class="light_option" value="Kelantan">Kelantan</option>
              <option class="light_option" value="Kuala Lumpur">Kuala Lumpur</option>
              <option class="light_option" value="Labuan">Labuan</option>
              <option class="light_option" value="Melaka">Melaka</option>
              <option class="light_option" value="Negeri Sembilan">Negeri Sembilan</option>
              <option class="light_option" value="Pahang">Pahang</option>
              <option class="light_option" value="Penang">Penang</option>
              <option class="light_option" value="Perak">Perak</option>
              <option class="light_option" value="Perlis">Perlis</option>
              <option class="light_option" value="Putrajaya">Putrajaya</option>
              <option class="light_option" value="Sabah">Sabah</option>
              <option class="light_option" value="Sarawak">Sarawak</option>
              <option class="light_option" value="Selangor">Selangor</option>
              <option class="light_option" value="Terengganu">Terengganu</option >
            </select>

            <label class="container">*I agree to the <a target="_blank" [routerLink]="['/tnc']">Terms and Conditions.</a>
              <input type="checkbox" checked="checked" formControlName="aggree">
              <span class="checkmark"></span>
            </label>
          </form>

        </div>
        <button [ngClass]="{'disable_button': !dataForm.valid}" [disabled]="!dataForm.valid" (click)="register()">Register</button>
        <div class="link_inline">Already have an account? <a class="cursor-pointer" (click)="login()">Log In</a></div>
        <img src="../../../assets/frame_bottom.png" class="bootom_frame" alt="">
      </div>
    </div>
  </div>
  <img src="../../../assets/register_frame.png" class="register_frame" alt="">
</div>
