import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services';
import { ForgotComponent } from '../forgot/forgot.component';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { LoginComponent } from '../login/login.component';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LoginSucceedComponent } from '../login/login-succeed/login-succeed.component';
import { UpdatePasswordSubmitedComponent } from './update-password-submited/update-password-submited.component';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class UpdatePasswordComponent implements OnInit {

  isPasswordMatchError = false;
  dataForm = new FormGroup({
    forgot_password_code: new FormControl('', Validators.required),
    new_password: new FormControl('', Validators.required),
    confirm_password: new FormControl('', Validators.required),
  });

  constructor(
    private _authService: AuthService,
    private _spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _activeRoute: ActivatedRoute,
  ) {
    const forgotPasswordCode = this._activeRoute.snapshot.paramMap.get('forgot_password_code');
    this.dataForm.get('forgot_password_code').setValue(forgotPasswordCode);
  }

  ngOnInit() {
  }

  updatePassword() {
      this._dialog.open(LoadingPopupComponent);
    // this._spinner.show();
    if(this.dataForm.get('new_password').value !== this.dataForm.get('confirm_password').value) {
      this.isPasswordMatchError = true;
      // this._spinner.hide();
      this._dialog.closeAll();

      return false;
    }

    this._authService.updatePassword(this.dataForm.value).subscribe((response: ApiResponse) => {
      setTimeout(() => {
        // this._spinner.hide();
        this._dialog.closeAll();
        this._dialog.open(UpdatePasswordSubmitedComponent);
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this._snackBar.open("Oops.. !, " + error.error.message, 'Close');
      // this._spinner.hide();
      this._dialog.closeAll();

    });
  }

}
