<a class="sub_footer cursor-pointer" [routerLink]="['/tnc']" target="_blank">Terms & Conditions. Privacy Policy.</a>
<div class="footer">
  <img src="../../../assets/logo_footer.png" alt="" class="logo">
  <p>2nd Floor, No. 2 Jalan Sri Hartamas 8, Sri Hartamas, 50480 Kuala Lumpur. <br/>+603-6201 0888</p>
  <div class="inline_footer">
    <div class="item">
      <span>Plenitude Berhad</span>
      <div class="socmed">
        <a href="https://www.youtube.com/channel/UCE8TAl4PuheTTCE_ROM8RNg" target="_blank"><img src="../../../assets/yt.png" alt=""></a>
        <a href="https://www.linkedin.com/company/plenitude-berhad/" target="_blank"><img src="../../../assets/in.png" alt=""></a>
      </div>
    </div>
    <div class="sparator_footer"></div>
    <div class="item">
      <span>Plenitude Property</span>
      <div class="socmed">
        <a href="https://www.facebook.com/plenitudeproperty" target="_blank"><img src="../../../assets/fb.png" alt=""></a>
        <a href="https://www.instagram.com/plenitude.property/" target="_blank"><img src="../../../assets/ig.png" alt=""></a>
      </div>
    </div>
    <div class="sparator_footer"></div>
    <div class="item">
      <span>Plenitude Hotels</span>
      <div class="socmed">
        <a href="https://www.facebook.com/PlenitudeHotels" target="_blank"><img src="../../../assets/fb.png" alt=""></a>
        <a href="https://www.instagram.com/plenitudehotels/" target="_blank"><img src="../../../assets/ig.png" alt=""></a>
      </div>
    </div>
    <div class="sparator_footer"></div>
    <div class="item">
      <span>Gurney Walk</span>
      <div class="socmed">
        <a href="https://www.facebook.com/gurneywalk" target="_blank"><img src="../../../assets/fb.png" alt=""></a>
        <a href="https://www.instagram.com/gurneywalk/" target="_blank"><img src="../../../assets/ig.png" alt=""></a>
      </div>
    </div>
  </div>
  <h5>Copyright 2024 Plenitude Berhad 200001028479 (531086-T). All Rights Reserved.</h5>
</div>
