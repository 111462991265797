<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="inner_modal login_modal">
    <div class="close_button" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="57.624" height="57.624" viewBox="0 0 57.624 57.624">
        <g id="Group_22085" data-name="Group 22085" transform="translate(-963.123 -61.188)">
          <rect id="Rectangle_6614" data-name="Rectangle 6614" width="73.192" height="8.3" rx="4.15" transform="translate(968.992 61.188) rotate(45)" fill="#fff"/>
          <rect id="Rectangle_6615" data-name="Rectangle 6615" width="73.192" height="8.3" rx="4.15" transform="translate(963.123 112.943) rotate(-45)" fill="#fff"/>
        </g>
      </svg>
    </div>
    <div class="box_dialog">
      <div class="box_dialog_content">
        <h1>Welcome <br/>Back!</h1>
        <p>Stand a chance to win Grand Prizes, with daily surprises of additional rewards waiting for you.</p>
        <div class="form">
          <form [formGroup]="loginForm">
            <input type="text" placeholder="Email Address" formControlName="email">
            <input type="password" placeholder="***" formControlName="password" (keyup.enter)="login()">

            <div class="inline_input">
              <label class="container">Remember me
                <input type="checkbox" name="gender" checked="checked">
                <span class="checkmark"></span>
              </label>
              <a (click)="forgot()" class="forgot">Forgot Password?</a>
            </div>
          </form>

        </div>
        <br/>
        <button (click)="login()" [ngClass]="{'disable_button': !loginForm.valid}" [disabled]="!loginForm.valid">Login</button>
        <div class="link_inline">Don't have an account?<a class="cursor-pointer" (click)="register()">Register</a></div>
        <img src="../../../assets/frame_bottom.png" class="bootom_frame" alt="">
      </div>
    </div>
  </div>
  <img src="../../../assets/login_frame.png" class="login_frame" alt="">
</div>
