import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RsvpFormSubmitedComponent } from '../rsvp-form-submited/rsvp-form-submited.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { User } from 'src/app/interfaces/user';
import { environment } from 'src/environments/environment';
import { OpenHouseService } from 'src/app/services/open-house.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingPopupComponent } from '../../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-rsvp-form',
  templateUrl: './rsvp-form.component.html',
  styleUrls: ['./rsvp-form.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class RsvpFormComponent implements OnInit {
  selectedLocation = [];
  dataForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    qty: new FormControl('', Validators.required),
    data: new FormControl([], Validators.required),
    locations: new FormControl('', Validators.required)
  })

  constructor(
    private _userService: UserService,
    private _openHouseService: OpenHouseService,
    private _spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<RsvpFormComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataForm.get('data').setValue(this.data);
    if(localStorage.getItem(environment.authIdentifierKey)) {
      this.getProfile();
    }
    console.log(data)
  }

  ngOnInit() {
  }

  getProfile() {
    this._userService.profile().subscribe((response: ApiResponse) => {
      const profile: User = response.data;
      this.dataForm.patchValue({
        name: profile.name,
        email: profile.username,
        phone: profile.phone,
      })
    });
  }

  selectLocation(event: any,) {
    if(event.target.checked) {
      this.selectedLocation.push(event.target.value);
    } else {
      const index = this.selectedLocation.indexOf(event.target.value);
      this.selectedLocation.splice(index, 1);
    }

    this.dataForm.get('locations').setValue(this.selectedLocation.join(','));
  }

  submit() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);

    const params = this.dataForm.value;
    this._openHouseService.attend(params).subscribe((response: ApiResponse) => {
      setTimeout(() => {
        this._dialogRef.close();
        // this._spinner.hide();
        this._dialog.closeAll();
        this._dialog.open(RsvpFormSubmitedComponent)
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      // this._spinner.hide();
      this._dialog.closeAll();
      this._snackBar.open(error.error.message, 'Close');
    });

  }

}
