export enum Enum {}

export enum HttpStatusCode {
    success = 200,
    successCreate = 201,
    emailNotConfirmed = 203,
    successDelete = 204,

    /* Error - Client Side */
    badRequest = 400,
    unauthorized = 401 /* User is not authorized (User not logged in) */,
    forbidden = 403 /* User doesn't have access to do the request (Logged in user doesn't have permission to access) */,
    notFound = 404,

    areaNotCovered = 490,

    /* Error - Server Side */
    internalServerError = 500 /* Internal Server Error */,
    badGateway = 502 /* Upstream Server Error */,
}

export enum GenderOption {
    male = 1,
    female = 0,
}

export enum PrizeType {
    bigPrize = 1,
    smallPrize = 2,
    greeting = 3,
}

export enum DeletedStatus {
    yes = 1,
    no = 0,
}

export enum UserStatus {
    confirmed = 1,
    unConfirmed = 0,
}

export enum UserTypeId {
    user = 1,
    admin = 2,
}

export enum FileType {
    xls = 'xls',
    xlsx = 'xlsx',
    doc = 'doc',
    docx = 'docx',
    pdf = 'pdf',
    jpg = 'jpg',
    jpeg = 'jpeg',
    png = 'png',
}

export enum SortDirection {
    asc = 'asc',
    desc = 'desc',
}

export enum IsCompleted {
    yes = 1,
    no = 0,
}

export enum IsAdmin {
    yes = 1,
    no = 0,
}

export enum IsActive {
    yes = 1,
    no = 0,
}


export enum Gender {
    male = 'm',
    female = 'f',
}

export enum DefaultValue {
    loadingTimeOut = 200,
}

export enum DefaultImage {
    logo = '../../../../../assets/images/empty-logo.png',
    appLogo = '../../../../../assets/images/logo.png',
    profile = '../../../../../assets/images/empty-profile.png',
    pageNotFound = '../../../../../assets/images/illustrations/404.svg',
    internalServerError = '../../../../../assets/images/illustrations/500.svg',
    sad = '../../../../../assets/images/illustrations/sad.svg',
    addMultipleImage = '../../../../../assets/images/add-multiple-image.png',
    emptyProduct = '../../../../../assets/images/no-product.png',
    emptyStore = '../../../../../assets/images/no-store.png',
    emptyBanner = '../../../../../assets/images/no-banner.jpeg',
    emptyReceipt = '../../../../../assets/images/empty-receipt.png',
}
