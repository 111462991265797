import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { RegisterComponent } from '../register/register.component';
import { LoginComponent } from '../login/login.component';
import { WelcomeComponent } from '../welcome/welcome.component';
import { PrizeComponent } from '../prize/prize.component';
import { environment } from 'src/environments/environment';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { AngpauService, UserService } from 'src/app/services';
import { Reward } from 'src/app/interfaces/reward';
import { User } from 'src/app/interfaces/user';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ])
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  @ViewChild('open_house') openhouseElement: ElementRef;

  accountMenu: boolean = false;
  isMoving : boolean = false;
  showMenu: boolean = false;
  isLoading: boolean = true;
  totalAngpau = 36
  totalCollected = 0;
  dailyRandomPrize: any = [];
  selectedRandomPrize: Reward;
  rewardToday: Reward[];
  isLogedIn = false;
  userData: any;
  isAngpau1Collected = false;
  isAngpau2Collected = false;
  profile: User;
  todayAngpao = false;
  step = 0;
  preplay = true;

  constructor(
    private _angpauService: AngpauService,
    private _userService: UserService,
    private _spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.totalCollected = 36;
    this.isAngpau1Collected = true;
    this.isAngpau2Collected = true;
    localStorage.removeItem(environment.authIdentifierKey);
    localStorage.removeItem(environment.authUser);
    localStorage.clear();
    setTimeout(() => {
        this.isLoading = false;
        this.isLogedIn = false;
      }, 2000)

   
    // if(localStorage.getItem(environment.authIdentifierKey)) {
    //   this.isLogedIn = true;
    //   this.myAngpauToday();
    //   this.userData = JSON.parse(localStorage.getItem(environment.authUser));
    // } else {
    //   setTimeout(() => {
    //     this.isLoading = false;
    //     this.isLogedIn = false
    //     this._dialog.open(WelcomeComponent, {data: {}});
    //   }, 2000)
    // }
  }

  ngOnInit() {
    setTimeout(() => {
      this.preplay = false
      const isParamjump = this.activatedRoute.snapshot.paramMap.get('section');

      if(isParamjump) {
        this.el.nativeElement.querySelector('#' + isParamjump).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      if(!this.isLogedIn && !isParamjump) {
        // this._dialog.open(WelcomeComponent, {data: {}});
      }

      // const prizeId = 'f1416460-aaa2-11ee-b205-02006791e399';
      // const prizeId = 'b08c109c-aaa2-11ee-b205-02006791e399';
      // const prizeId = '573051fe-a30d-4676-a92b-1543aad06660';
      // const prizeId = '9247ad1e-aaa2-11ee-b205-02006791e399';
      // const prizeId = '9d79c4cd-3418-4599-8a29-e93e19ba6131';
      // const prizeId = 'ca9744c0-e033-4a71-9df7-a01abe2845ee';
      // const prizeId = 'e5da1c5d-75e7-4d0b-9b2d-0f48fbb8a496';

      // const prizeId = '98b9957a-65e3-4b6d-8d29-5fe747d6864f';
      // const prizeId = 'c152ca35-9af9-48f2-8af9-dfd68cccd2a5';
      // const prizeData = this.prizeData(prizeId);
      // const  reward: Reward =  {
      //     id: '234567898765',
      //     user_id: '3456787654678',
      //     prize_id: prizeId,
      //     angpau_no: 1,
      //     is_claim: 1,
      //     type: prizeData.type,
      //     data: prizeData,
      //     personal_info: null,
      //     created_at: '83093902',
      //     updated_at: '3983029392032',
      //     deleted: 0
      // }
      // this._dialog.open(PrizeComponent, {data: reward});

    },4000)
  }

  myAngpauToday() {
    this._angpauService.todayAngpau().subscribe((response: ApiResponse) => {
      const angpau: Reward[] = response.data;
      // this.totalCollected = response.total;
      this.totalCollected = 36;

      this.step = this.totalCollected * 35;
      this.rewardToday = angpau;

      if(this.totalCollected >= 36) {
        this.isAngpau1Collected = true;
        this.isAngpau2Collected = true;
      } else {
        this.rewardToday.forEach((value: Reward, index: number) => {
          if(value.angpau_no == 1) {
            this.isAngpau1Collected = true;
          }
          if(value.angpau_no == 2) {
            this.isAngpau2Collected = true;
          }
        });
      }


      setTimeout(() => {
        this.isLoading = false;
      }, 2000)
    });
  }

  findRewardToday(no: number) {
    const exist = this.rewardToday.filter(reward => reward.angpau_no === no);
    return exist.length > 0 ? true:false;
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  onDragStart() {
    this.isMoving = true;
    // console.log('drag start');
  }

  onDragEnd() {
    this.isMoving = false;
    // console.log('drag end');
  }

  onScroll(event: Event) {
    if (event.returnValue) {
      this.isMoving = true
      setInterval(() => {
        this.isMoving = false
      }, 1000)
    }
  }

  collect(angpauNo: number) {
    if(!this.isLogedIn) {
      this._snackBar.open('Please login to collect your angpau', 'Close')
      this.login();
      return false;
    }
    this._dialog.open(LoadingPopupComponent);
    // this._spinner.show();

    const params = {
      angpau_no: angpauNo
    }
    this._angpauService.colect(params).subscribe((response: ApiResponse) => {
      const reward: Reward = response.data;
      this.selectedRandomPrize = reward;
      this.rewardToday.push(reward);
      if(angpauNo === 1) {
        this.isAngpau1Collected =  true;
      }

      if(angpauNo === 2) {
        this.isAngpau2Collected =  true;
      }
      this.totalCollected = this.totalCollected + 1;

      setTimeout(() => {
        // this._spinner.hide();
        this._dialog.closeAll();
        const prizeDialog = this._dialog.open(PrizeComponent, {data: this.selectedRandomPrize})
        prizeDialog.afterClosed().subscribe( _=> {
        //  if(reward.type != 1) {
          this.walk();
        //  }
        });

      }, DefaultValue.loadingTimeOut);
    });
  }

  smallPow() {
    if(!this.isLogedIn) {
      this._snackBar.open('Please login to collect your angpau', 'Close')
      this.login();
      return false;
    }
    this._dialog.open(LoadingPopupComponent);
    // this._spinner.show();


    this._angpauService.smallPow().subscribe((response: ApiResponse) => {
      const reward: Reward = response.data;
      this.selectedRandomPrize = reward;
      this.rewardToday.push(reward);
      this.isAngpau2Collected =  true;
      this.totalCollected = this.totalCollected + 1;

      setTimeout(() => {
        // this._spinner.hide();
        this._dialog.closeAll();
        const prizeDialog = this._dialog.open(PrizeComponent, {data: this.selectedRandomPrize})
        prizeDialog.afterClosed().subscribe( _=> {
        //  if(reward.type != 1) {
          this.walk();
        //  }
        });

      }, DefaultValue.loadingTimeOut);
    });
  }

  bigPow() {
    if(!this.isLogedIn) {
      this._snackBar.open('Please login to collect your angpau', 'Close')
      this.login();
      return false;
    }
    this._dialog.open(LoadingPopupComponent);
    // this._spinner.show();


    this._angpauService.bigPow().subscribe((response: ApiResponse) => {
      const reward: Reward = response.data;
      this.selectedRandomPrize = reward;
      this.rewardToday.push(reward);
      this.isAngpau1Collected =  true;
      this.totalCollected = this.totalCollected + 1;

      setTimeout(() => {
        // this._spinner.hide();
        this._dialog.closeAll();
        const prizeDialog = this._dialog.open(PrizeComponent, {data: this.selectedRandomPrize})
        prizeDialog.afterClosed().subscribe( _=> {
        //  if(reward.type != 1) {
          this.walk();
        //  }
        });

      }, DefaultValue.loadingTimeOut);
    });
  }

  // loading() {
  //   this.showMenu = false;
  //   const dialog = this._dialog.open(LoadingPopupComponent, {
  //     width: '100vw',
  //   });
  // }

  register() {
    this.showMenu = false;
    const dialog = this._dialog.open(RegisterComponent, {
      width: '100vw',
    });
  }

  login() {
    this.showMenu = false;
    const dialog = this._dialog.open(LoginComponent, {
      width: '100vw',
    });
  }

  scroll(el: HTMLElement) {
    this.showMenu = false;
    el.scrollIntoView({behavior: 'smooth'});
  }

  showAccount() {
    this._dialog.open(LoadingPopupComponent);
    this._userService.profile().subscribe((response: ApiResponse) => {
      const profile: User = response.data;
      this.profile = profile;
      setTimeout(() => {
        this.accountMenu = true;
        this._dialog.closeAll();
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this._dialog.closeAll();
      this.isLogedIn = false;
    })
  }

  logOutButtonClicke() {
    window.location.href = '/'
  }

  walk() {
    if (this.step === 0) {
      this.step = 35
      this.isMoving = true
      setTimeout(() => {
        this.isMoving = false
      }, 1000);
      // this.totalCollected = this.totalCollected + 1
      if (this.rewardToday.length === 2) {
        setTimeout(() => {
          this.todayAngpao = true
        }, 1000);
      }
    } else {
      this.step = this.step + 35
      this.isMoving = true
      setTimeout(() => {
        this.isMoving = false
      }, 1000);
      // this.totalCollected = this.totalCollected + 1
      if (this.rewardToday.length === 2) {
        setTimeout(() => {
          this.todayAngpao = true
        }, 1000);
      }
    }
  }

  prizeData(id: string) {
    const data =   [
      {
        id: '13aaaba6-1a8a-4008-bc43-6825f80e82d6',
        name: 'Wishing you many years of joy and good luck!',
        image: 'greetings/2.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '343e341c-152c-40ee-b382-404661a8a5f9',
        name: 'May prosperity and affluence grace your journey!',
        image: 'greetings/3.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '573051fe-a30d-4676-a92b-1543aad06660',
        name: 'RM68 Hotel Cash Voucher',
        image: 'big-prize/4.png',
        description: '',
        type: 1,
        qty: 8,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2024-01-13 07:09:33',
      },
      {
        id: '6c242781-e3ed-4f1e-ab71-8360d9c99d24',
        name: 'May you soar on the wings of success through an era of abundance and prosperity!',
        image: 'greetings/6.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '78433aa7-0d27-4de3-8ed8-aa000801da28',
        name: 'Wishing you the spirit and vitality that inspire greatness!',
        image: 'greetings/5.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '9247ad1e-aaa2-11ee-b205-02006791e399',
        name: 'RM38 Touch ‘n’ Go eWallet Credit',
        image: 'big-prize/3.png',
        description: null,
        type: 1,
        qty: 8,
        createdAt: null,
        updatedAt: '2024-01-14 06:23:23',
      },
      {
        id: '98b9957a-65e3-4b6d-8d29-5fe747d6864f',
        name: 'Open House',
        image: 'small-prize/2.png',
        description: 'Celebrate the new year with Plenitude Property. More exciting events, more joy!',
        type: 2,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '9cea38f4-5f19-44eb-b6ba-c0d74b4475d1',
        name: 'May the Year of the Dragon bring you abundant blessings and prosperity!',
        image: 'greetings/4.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: '9d79c4cd-3418-4599-8a29-e93e19ba6131',
        name: 'RM100 IKEA Gift Card',
        image: 'big-prize/7.png',
        description: '',
        type: 1,
        qty: 8,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: 'a6e73715-51df-488a-b399-6c120e05ded7',
        name: 'Wishing you a journey of success and may all your wishes come true!',
        image: 'greetings/1.png',
        description: '',
        type: 3,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: 'b08c109c-aaa2-11ee-b205-02006791e399',
        name: 'RM18 Touch ‘n’ Go eWallet Credit',
        image: 'big-prize/2.png',
        description: null,
        type: 1,
        qty: 18,
        createdAt: null,
        updatedAt: '2024-01-14 05:26:40',
      },
      {
        id: 'c152ca35-9af9-48f2-8af9-dfd68cccd2a5',
        name: 'Hotel Offers',
        image: 'small-prize/1.png',
        description: 'Immerse in a splendid stay and unforgettable dining experience at Plenitude Hotels.',
        type: 2,
        qty: 1,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2023-12-28 05:42:04',
      },
      {
        id: 'ca9744c0-e033-4a71-9df7-a01abe2845ee',
        name: 'RM88 Hotel Cash Voucher',
        image: 'big-prize/5.png',
        description: '',
        type: 1,
        qty: 8,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2024-01-13 07:09:22',
      },
      {
        id: 'e5da1c5d-75e7-4d0b-9b2d-0f48fbb8a496',
        name: 'RM138 Hotel Cash Voucher',
        image: 'big-prize/6.png',
        description: '',
        type: 1,
        qty: 8,
        createdAt: '2023-12-28 05:42:04',
        updatedAt: '2024-01-14 06:24:48',
      },
      {
        id: 'f1416460-aaa2-11ee-b205-02006791e399',
        name: 'RM8 Touch ‘n’ Go eWallet Credit',
        image: 'big-prize/1.png',
        description: null,
        type: 1,
        qty: 38,
        createdAt: null,
        updatedAt: '2024-01-14 05:22:47',
      },
    ];

    const dataIndex = data.findIndex( prize => prize.id === id);
    return data[dataIndex];
  }



}
