import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse, api } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _httpClient: HttpClient) {}

  
  emailConfirmation(code: string): Observable<ApiResponse> {
      return this._httpClient.get<ApiResponse>(
          api.auth.verifyEmail.concat(code)
      );
  }
  
  register(params: any): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.auth.register,
          params
      );
  }
  
  login(params: LoginParam): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.auth.login,
          params
      );
  }
  
  forgotPassword(params: any): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.auth.password.forgotPassword,
          params
      );
  }
  
  updatePassword(params: any): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.auth.password.updatePassword,
          params
      );
  }
  
  logOut(): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.private.logOut, {}
      );
  }
}

export interface LoginParam {
  email: string;
  password: string;
}

export interface RegisterParam {
  name: string;
  email: string;
  phone: string;
  password: string;
}
