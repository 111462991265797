// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api-plenitudecny.creativebugs.my/',
  // apiBaseUrl: 'https://api.plenitudecny.xyz/',
  // apiBaseUrl: 'http://127.0.0.1:8000/',
  uiAvatarApi: 'https://ui-avatars.com/api/?',
  authIdentifierKey: "5178b591d8d3e5eaab74b7ee66713e08",
  authUser: "657206d1de54ba8925cd370f4ffbb410",
  whatsAppUniversalLink: 'https://wa.me/',
  wazeUniversalLink: 'https://www.waze.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
