import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/utils';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class WelcomeComponent implements OnInit {

  isLoggedIn = false;
  constructor(
    private _dialogRef: MatDialogRef<WelcomeComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ApiResponse
  ) { 
    if(localStorage.getItem(environment.authIdentifierKey)) {
      this.isLoggedIn = true;
    }
  }

  ngOnInit() {
  }

  login() {
    this._dialogRef.close();
    const dialog = this._dialog.open(LoginComponent, {
      width: '100vw',
    });
  }
  register() {
    this._dialogRef.close();
    const dialog = this._dialog.open(RegisterComponent, {
      width: '100vw',
    });
  }

}
