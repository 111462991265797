<div class="tnc">
  <div class="content">
    <img src="../../../assets/banner_tnc.jpeg" class="banner_tnc">
    <h1>TERMS AND CONDITIONS</h1>
    <p>These terms and conditions govern
      the Plenitude’s Chinese New Year 2024 Campaign on <a href="http://www.cny2024.plenitude.com.my/" class="a"
        target="_blank">https://www.cny2024.plenitude.com.my</a> and any other official channels
      including social media pages.</p>
    <p>By
      participating, participants are deemed to have read, understood, accepted, and agreed to be bound by these terms
      and conditions, including any amendments, variations, or updates. Participants unequivocally accept these terms in
      their entirety.</p>
    <p>To participate in the campaign,
      participants have agreed to provide their contact details and to receive communications and promotional materials
      from Plenitude and its group of companies. Plenitude reserves the right to publish the photographs or videos of
      the participants taken during events, promotional activities, and/or prize presentations in relation to this
      campaign on printed materials, digital and social media channels, news media, and any other channels deemed
      relevant.</p>
    <ol id="l1">
      <li data-list-text="1.">
        <h1>1. ORGANISER</h1>
        <p>Plenitude Management Services Sdn Bhd</p>
      </li>
      <li data-list-text="2.">
        <h1>2. NAME OF THE CONTEST</h1>
        <p>Plenitude’s Chinese New Year 2024 Campaign -
          Prosperous Fortune Abounds</p>
      </li>
      <li data-list-text="3.">
        <h1>3. CONTEST PERIOD</h1>
        <p>From 18 January 2024 at 00:00:01 to 16
          February 2024 at 23:59:59. The Organiser reserves the right to modify the campaign period without prior
          notice.</p>
      </li>
      <li data-list-text="4.">
        <h1>4. ELIGIBILITY CRITERIA</h1>
        <p>The contest is open to all Malaysians and
          Permanent Residents of Malaysia aged eighteen (18) and above.</p>
      </li>
      <li data-list-text="5.">
        <h1>5. INELIGIBILITY</h1>
        <p>The following persons are NOT eligible to
          participate:</p>
        <ol id="l2">
          <li data-list-text="5.1.">
            <p>5.1. Employees of Plenitude and its
              subsidiaries, including their immediate family members.</p>
          </li>
          <li data-list-text="5.2.">
            <p>5.2. Representatives and
              agents of Plenitude and its subsidiaries, including relevant advertising and promotion agencies and
              vendors directly involved with the contest.</p>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h1>6. CONTEST MECHANISM</h1>
        <ol id="l3">
          <li data-list-text="6.1.">
            <p>6.1. The entry procedure and
              mechanism can be viewed at <a href="http://www.cny2024.plenitude.com.my/" class="a"
                target="_blank">https://www.cny2024.plenitude.com.my</a>, the Facebook &amp; Instagram pages of
              (Plenitude Property) and (Plenitude Hotels). Participants must adhere to the entry procedure set by the
              Organiser.</p>
          </li>
          <li data-list-text="6.2.">
            <p>6.2. Participants meeting
              the eligibility criteria are entitled to participate and stand a chance to win daily surprise rewards and
              the Grand Prizes.</p>
          </li>
        </ol>
      </li>
      <li data-list-text="7.">
        <h1>7. CONTEST CRITERIA</h1>
        <ol id="l4">
          <li data-list-text="7.1.">
            <p>7.1. Participants must collect 2X 'Ang Pao'
              daily and log in on any 18 days within the campaign for eligibility.</p>
          </li>
          <li data-list-text="7.2.">
            <p>7.2. Participants must accumulate 36 'Ang Pao'
              for a chance to win the Grand Prizes.</p>
          </li>
        </ol>
      </li>
      <li data-list-text="8.">
        <h1>8. DISQUALIFICATION</h1>
          <ol id="l6">
            <li data-list-text="8.1">
              <p>8.1 The Organiser reserves the right to
                disqualify any Participant and/or revoke the Prize at any stage of the Contest, if: -</p>
              <ol id="l7">
                <li data-list-text="8.1.1">
                  <p>8.1.1 Participant becomes ineligible or
                    does not meet any of the Eligibility Criteria.</p>
                </li>
                <li data-list-text="8.1.2">
                  <p>8.1.2 The Participant
                    breaches any of these Terms and Conditions or procedure and mechanism of the Contest, or other rules
                    and regulations of the Contest, or violates any applicable laws or regulations.</p>
                </li>
                <li data-list-text="8.1.3">
                  <p>8.1.3 The Organiser, in its sole
                    discretion, believes that the Participant has attempted to undermine the operation of the Contest by
                    fraud, deception or cheating.</p>
                </li>
              </ol>
            </li>
            <li data-list-text="8.2">
              <p>8.2 In
                the event of a disqualification, the Organiser reserves the right to demand for the return of the Prize
                in its original condition or payment of its value from the said disqualified Participant.</p>
            </li>
          </ol>
      </li>
      <li data-list-text="9.">
        <h1>9. PRIZE</h1>
        <ul id="l8">
          <li data-list-text="9.1.">
            <p>9.1. <b>Winner</b> : Winner(s) will be selected randomly. Winners will be notified via phone call or email. Final
                confirmation of winners and prizes won are subject to further verification of eligibility and
                confirmation by the Organiser. Winners will be announced via an Announcement Post on the Facebook &amp;
                Instagram pages of (Plenitude Property) and (Plenitude Hotels) on 22 February 2024. Winners must message
                Plenitude Property or Plenitude Hotels via Facebook Messenger within FIVE (5) days after the winner
                announcement. The winners are required to submit their personal details as set out below:</p>
                <ul id="l9">
                  <li data-list-text="•">
                    <p>Full name (as per NRIC):</p>
                  </li>
                  <li data-list-text="•">
                    <p>NRIC No.:</p>
                  </li>
                  <li data-list-text="•">
                    <p>Mobile number:</p>
                  </li>
                  <li data-list-text="•">
                    <p>Email address:</p>
                  </li>
                  <li data-list-text="•">
                    <p>Mailing address:</p>
                  </li>
                </ul>
          </li>
          <li data-list-text="9.2.">
            <p>9.2. <b>Grand Prize Winner
              Selection</b><span class="p">: Two (2) grand prize winners will be randomly selected via a lucky draw by using
                a third-party tool. Winners must fulfil all the criteria as stated in the "Contest Criteria" section
                above.</span></p>
          </li>
          <li data-list-text="9.3.">
            <p>9.3. <b>Surprise Reward
              Redemption</b><span class="p">: The redemption of surprise rewards is on a first-come, first-served basis,
                subject to availability. Each eligible participant is only entitled to redeem a maximum of One (1)
                surprise reward.</span></p>
          </li>
          <li data-list-text="9.4.">
            <p>9.4. <b>E-wallet Credit
              Redemption</b><span class="p">: Touch 'n Go e-Wallet credit will be credited to a VALID Touch 'n Go account or
                given a PIN for manual top-up with a crediting period of up to fourteen (14) working days. The prizes
                will be forfeited if winners fail to furnish their personal details and are subjected to further terms
                and conditions by Touch ‘n Go. By accepting the prizes, the winners agree to abide by such further terms
                and conditions.</span></p>
          </li>
          <li data-list-text="9.5.">
            <p>9.5. <b>Grand Prize
              Collection</b><span class="p">: Winners are required to collect the prizes from the designated Plenitude's
                Sales Gallery and/or offices within the prize collection date and time as mutually agreed by both the
                winners and Plenitude. Winners are responsible of making own transport arrangements and at own cost. The
                Organiser will not be responsible for any claims, losses, or damages resulting from or in connection
                with this Campaign or the prizes given out through this Campaign. In the unlikely event that the prize
                is unavailable, the Organiser reserves the right to replace the prize with another product of similar
                value.</span></p>
          </li>
          <li data-list-text="9.6.">
            <p>9.6. All winners are to
              liaise directly with the brands or vendors on prize redemption / usage / booking and any warranty matters.
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="10.">
        <h1>10. MODIFICATION OF CONTEST</h1>
        <p>The Organiser reserves the
          right to change, amend, delete, or add to the terms and conditions and contest mechanism at its absolute
          discretion.</p>
      </li>
      <li data-list-text="11.">
        <h1>11. PRIVACY POLICY</h1>
      </li>
    </ol>
    <p>By participating in the
      Contest, Participants acknowledge and agree that the Organiser will gain access to, use, disclose, retain and
      otherwise process certain Personal Data provided by the Participants for the purpose of the Contest. The
      Organiser’s applicable Personal Data and Privacy Policy is as set out at <a href="https://plenitude.com.my/pdpa/" target="_blank">https://plenitude.com.my/pdpa/</a> (as may be amended from time to time). In the event the
      Participants withdraw their consent to the access, use, disclosure, retention and process of their Personal Data,
      the Participants will be disqualified and ineligible for consideration for the Contest and the Prizes.</p>
  </div>
</div>
