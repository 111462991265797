import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { RegisterSubmitedComponent } from './register-submited/register-submited.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginComponent } from '../login/login.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class RegisterComponent implements OnInit {

  dataForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.minLength(6)]),
    password: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    aggree: new FormControl(false, Validators.requiredTrue),

  })

  constructor(
    private _dialogRef: MatDialogRef<RegisterComponent>,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  register() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);
    const form = this.dataForm.value;
    const params: any = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      state: form.state,
      password: form.password,
      confirm_password: form.password,
    }

    this._authService.register(params).subscribe((response: ApiResponse) => {
      setTimeout(() => {
        this._dialogRef.close();
        // this._spinner.hide();
        this._dialog.closeAll();
        const data = this.dataForm.value;
        this._dialog.open(RegisterSubmitedComponent, {data: {email: data.email}})

      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this._snackBar.open("Oops.. !" + error.error.message, 'Close');
      // this._spinner.hide();
      this._dialog.closeAll();
    });
  }

  login() {
    this._dialogRef.close();
    this._dialog.open(LoginComponent, {data: {}})
  }

  close() {
    this._dialogRef.close();
  }

}
