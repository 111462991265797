import { Component, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-succeed',
  templateUrl: './login-succeed.component.html',
  styleUrls: ['./login-succeed.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoginSucceedComponent implements OnInit {

  constructor(
    private _router: Router,
    private _dialogRef: MatDialogRef<LoginSucceedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  done() {
    // this._dialogRef.close();
    // this._router.navigate(['/']);
    window.location.href = '/'
  }

}
