<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="box_dialog clean_dialog">
    <div class="box_dialog_content">
      <h1>Fill in your details below<br/>
        to claim your prize</h1>
      <div class="form">
       <form [formGroup]="dataForm">
        <input type="text" placeholder="Name" formControlName="name" autocomplete="off">
        <input type="text" placeholder="IC / Passport Number" formControlName="passport_number" autocomplete="off">
        <input type="number" placeholder="Contact Number" formControlName="phone" allowNumberOnly autocomplete="off">
        <input type="email" placeholder="Email" formControlName="email" autocomplete="off">
        <small class="help-block text-red" *ngIf="dataForm.get('email')?.value !=''  &&  !dataForm.get('email')?.valid">Invalid email format: e.g. example@domain.com</small>
        <textarea placeholder="Mailing Address" formControlName="mailing_address"></textarea>
        <div class="inline_grid">
          <div class="input_group">
            <label>Postal Code</label>
            <input type="number" placeholder="Postal Code" formControlName="postal_code" allowNumberOnly>
          </div>
          <div class="input_group">
            <label>City</label>
            <input type="text" placeholder="City" formControlName="city">
          </div>
        </div>
        <div class="input_group single_grid">
          <label>State</label>
          <input type="text" placeholder="State" formControlName="state">
        </div>
       </form>
      </div>
      <button (click)="claim()" [ngClass]="{'disable_button': !dataForm.valid}" [disabled]="!dataForm.valid">Claim</button>
      <button class="clean_btn" mat-dialog-close>Back</button>
    </div>
  </div>
  <img src="../../../assets/fame_claim.png" alt="" class="fram_box">
</div>
