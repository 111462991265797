import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services';
import { LoginComponent } from '../login/login.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, DefaultValue } from 'src/app/utils';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  isLoading = true;
  isError = false;
  message: string = '';
  code: string = '';
  constructor(
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) {
    this.code = this._activeRoute.snapshot.paramMap.get('code');
    this.verify();
  }

  ngOnInit() {
  }

  verify() {

    this._authService.emailConfirmation(this.code).subscribe((response: ApiResponse) => {
      setTimeout(() => {
        this.isLoading = false;
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this.message = error.error.message;
      this.isLoading = false;
      this.isError = true;
    });
  }

  login() {
    const loginDialog = this._dialog.open(LoginComponent, {data: {}})
    loginDialog.afterClosed().subscribe((response) => {
      // this._router.navigate(['/'])
    })
  }

}
