import { Component, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { PrizeClaimComponent } from '../prize-claim/prize-claim.component';
import { Reward } from 'src/app/interfaces/reward';
import { PrizeType } from 'src/app/utils';

@Component({
  selector: 'app-prize',
  templateUrl: './prize.component.html',
  styleUrls: ['./prize.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class PrizeComponent implements OnInit {

  prizeType = PrizeType;
  constructor(
    private _dialogRef: MatDialogRef<PrizeComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Reward

  ) { }

  ngOnInit() {
  }

  Redeem() {
    this._dialogRef.close();
    this._dialog.open(PrizeClaimComponent, {data: this.data})
  }

}
