import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, api } from '../utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AngpauService {

  constructor(private _httpClient: HttpClient) {}

  
  colect(params: any): Observable<ApiResponse> {
      return this._httpClient.get<ApiResponse>(
          api.private.angPau.list, {params: params}
      );
  }
  
  smallPow(): Observable<ApiResponse> {
      return this._httpClient.get<ApiResponse>(
          api.private.angPau.smallPow, {}
      );
  }
  
  bigPow(): Observable<ApiResponse> {
      return this._httpClient.get<ApiResponse>(
          api.private.angPau.bigPow, {}
      );
  }
  
  claim(params: any): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.private.angPau.claim,
          params
      );
  }
  
  todayAngpau(): Observable<ApiResponse> {
      return this._httpClient.get<ApiResponse>(
          api.private.angPau.today
      );
  }
}
