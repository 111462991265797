<div *ngIf="isLoading">
  <div class="loading_box">
    <img src="../../../assets/badge.png" alt="" [ngsReveal]="{origin: 'top', scale: 1, distance: '10px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
    <img class="bg_loading" src="../../../assets/bg_loading.jpg" alt="" [ngsReveal]="{origin: 'bottom', scale: 1, distance: '10px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
    <div class="loading-text app-loading">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
  </div>
</div>
<div class="dialog_modal" [@dialogEnter] [@dialogExit] *ngIf="!isLoading">
  <div class="box_dialog clean_dialog">
    <div class="box_dialog_content" *ngIf="!isError">
      <h1 class="large_text">Happy Chinese
        <br/>New Year! </h1>
        <svg xmlns="http://www.w3.org/2000/svg" width="234.87" height="234.87" viewBox="0 0 234.87 234.87">
          <g id="Group_20351" data-name="Group 20351" transform="translate(-7185.995 -6242.682)">
            <circle id="Ellipse_10285" data-name="Ellipse 10285" cx="117.435" cy="117.435" r="117.435" transform="translate(7185.995 6242.682)" fill="#ffdaad"/>
            <path id="Path_63045" data-name="Path 63045" d="M7258.927,6370.6l25.71,25.71,63.3-63.3" fill="none" stroke="#ac1f1f" stroke-linecap="round" stroke-linejoin="round" stroke-width="13.057"/>
          </g>
        </svg>
      <h1 class="normal_font">Your email has been successfully confirmed!</h1>
      <!-- <p>Please login to collect 36 Ang Paos within the contest period and stand a chance to win Big Prizes!.</p> -->
      <button (click)="login()">Login</button>
    </div>
    <div class="box_dialog_content" *ngIf="isError">
      <h1 class="normal_font">Oops.. something when wrong</h1>
      <p>{{ message }}</p>
    </div>
  </div>
  <img src="../../../assets/frame_small.png" alt="" class="fram_box">
</div>
