import { environment } from "src/environments/environment";

const apiBaseUrl: string = environment.apiBaseUrl.concat('api/');
const apiAppBaseUrl: string = apiBaseUrl.concat('app/');
const privateUrl: string = apiBaseUrl.concat('private/');

export const api = {
    open: {
        paymentMethod: apiBaseUrl.concat('private/open/payment-method'),
        deliveryService: apiBaseUrl.concat('private/open/delivery-service'),
        deliveryServiceDefault: apiBaseUrl.concat('private/open/delivery-service/default'),
        paymentMethodByIdentifier: apiBaseUrl.concat('private/open/payment-method-by-identifier/'),
    },
    auth: {
        register: apiBaseUrl.concat('auth/register'),
        login: apiBaseUrl.concat('auth/login'),
        verifyEmail: apiBaseUrl.concat('auth/confirm-email/'),
        password: {
            forgotPassword: apiBaseUrl.concat('auth/password/request-reset'),
            updatePassword: apiBaseUrl.concat('auth/password/update'),
        },
    },
    openHouse: {
        attend: apiBaseUrl.concat('open-house/attend')
    },
    private: {
        logOut: privateUrl.concat('logout'),
        user: {
            profile: privateUrl.concat('user/profile'),
        },
        angPau: {
            list: privateUrl.concat('angpau'),
            smallPow: privateUrl.concat('angpau/small-pow'),
            bigPow: privateUrl.concat('angpau/big-pow'),
            claim: privateUrl.concat('angpau/claim'),
            today: privateUrl.concat('angpau/today'),
        }
    },
};
