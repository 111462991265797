import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  tnc() {
    window.open('www.plenitude.com.my/cny2024/terms-and-conditions/', '_blank');
  }

}
