import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const filterValue = (value: string): string => value.replace(/[^0-9]*/g, '');

@Directive({
  selector: '[allowNumberOnly]',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AllowNumberOnlyDirective),
        multi: true,
    },
],
})
export class AllowNumberOnlyDirective {

  private onChange: (val: string) => void;
  private onTouched: () => void;
  private value: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string): void {
      const filteredValue: string = filterValue(value);
      this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  @HostListener('blur')
  onBlur(): void {
      this.onTouched();
  }

  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.renderer.setProperty(
          this.elementRef.nativeElement,
          'disabled',
          isDisabled
      );
  }

  writeValue(value: any): void {
      value = value ? String(value) : '';
      this.updateTextInput(value, false);
  }

  private updateTextInput(value: string, propagateChange: boolean): void {
      this.renderer.setProperty(
          this.elementRef.nativeElement,
          'value',
          value
      );
      if (propagateChange) {
          this.onChange(value);
      }
      this.value = value;
  }

}
