import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AppComponent } from './app.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { TncComponent } from './pages/tnc/tnc.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'home/:section', component: HomeComponent},
      {path: 'tnc', component: TncComponent},
      {path: 'email-confirmation/:code', component: VerifyEmailComponent},
      {path: 'update-password/:forgot_password_code', component: UpdatePasswordComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],

})
export class AppRoutingModule { }
