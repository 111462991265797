import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hotel-offer',
  templateUrl: './hotel-offer.component.html',
  styleUrls: ['./hotel-offer.component.scss']
})
export class HotelOfferComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    // autoWidth:true,
    center: true,
    margin: 4,
    stagePadding: 40,
    navText: ['', ''],
    autoplay: true,
    rewind: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      768: {
        items: 1
      },
      940: {
        stagePadding:495,
        items: 1,
      }
    },
    nav: false
  }


  hotelOffers: any = [
    {
      id: 1,
      location: 'Kuala Lumpur',
      offers: [
        {
          id: 101,
          name: 'Grand Hotel KLa',
          discount: 20,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 1,
          img: '../../../assets/offers/kl/KL_DOM_Stay.png'
        },
        {
          id: 102,
          name: 'Luxury Towers',
          discount: 15,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 1,
          img: '../../../assets/offers/kl/KL_NV_Stay.png'
        },
        {
          id: 103,
          name: 'City Center Suites',
          discount: 25,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 1,
          img: '../../../assets/offers/kl/KL_OAK_Stay.png'
        },
      ],
    },
    {
      id: 2,
      location: 'Ipoh',
      offers: [
        {
          id: 106,
          name: 'Heritage Boutique Hotel',
          discount: 18,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 2,
          img: '../../../assets/offers/ipoh/IP_TRA_Stay.png',
        },
      ],
    },
    {
      id: 3,
      location: 'Penang',
      offers: [
        {
          id: 111,
          name: 'Seaview Paradise Hotel',
          discount: 18,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_ASC_Stay.png',
        },
        {
          id: 112,
          name: 'Heritage Mansion',
          discount: 20,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_MP_Stay.png',
        },
        {
          id: 113,
          name: 'Island Retreat Resorts',
          discount: 15,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_TP_Stay.png',
        },
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_TRA_Stay.png',
        },
      ],
    },
    {
      id: 4,
      location: 'Osaka',
      offers: [
        {
          id: 116,
          name: 'Zen Garden Hotel',
          discount: 18,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 4,
          img: '../../../assets/offers/japan/JP_TRA_Stay1.png',
        },
      ],
    },
    {
      id: 5,
      location: 'Kyoto',
      offers: [
        {
          id: 117,
          name: 'Sakura Blossom Inn',
          discount: 25,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 4,
          img: '../../../assets/offers/japan/JP_TRA_Stay2.png',
        },
      ],
    },
    {
      id: 6,
      location: 'Seoul',
      offers: [
        {
          id: 126,
          name: 'Gangnam Style Hotel',
          discount: 20,
          url: 'https://linktr.ee/plenitudehotelsdeals',
          parentId: 6,
          img: '../../../assets/offers/korea/KR_TRA_Stay.png',
        },
      ],
    },
  ];


  dineOffers: any = [
    {
      id: 1,
      location: 'Kuala Lumpur',
      offers: [
        {
          id: 101,
          name: 'OakHotel',
          discount: 20,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 1,
          img: '../../../assets/offers/kl/KL_OAK_DINE1.png'
        },
        // {
        //   id: 102,
        //   name: 'OakHotel',
        //   discount: 15,
        //   url: 'https://linktr.ee/plenitudehotelsFnBOffers',
        //   parentId: 1,
        //   img: '../../../assets/offers/kl/KL_OAK_DINE2.png'
        // },
      ],
    },
    {
      id: 2,
      location: 'Ipoh',
      offers: [
        {
          id: 106,
          name: 'Heritage Boutique Hotel',
          discount: 18,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 2,
          img: '../../../assets/offers/ipoh/IP_TRA_Dine.png',
        },
      ],
    },
    {
      id: 3,
      location: 'Penang',
      offers: [
        {
          id: 111,
          name: 'Ascott',
          discount: 18,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_ASC_Dine1.png',
        },
        {
          id: 112,
          name: 'Heritage Mansion',
          discount: 20,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_ASC_Dine2.png',
        },
        {
          id: 113,
          name: 'Island Retreat Resorts',
          discount: 15,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_ASC_Dine3.png',
        },
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_MP_Dine1.png',
        },
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: 'https://linktr.ee/plenitudehotelsFnBOffers',
          parentId: 3,
          img: '../../../assets/offers/penang/PN_MP_Dine2.png',
        },
      ],
    },
    {
      id: 4,
      location: 'Osaka',
      offers: [
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: '#',
          parentId: 3,
          img: '../../../assets/dining_coming.png',
        },
      ],
    },
    {
      id: 5,
      location: 'Kyoto',
      offers: [
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: '#',
          parentId: 3,
          img: '../../../assets/dining_coming.png',
        },
      ],
    },
    {
      id: 6,
      location: 'Seoul',
      offers: [
        {
          id: 114,
          name: 'Charming Boutique Penang',
          discount: 22,
          url: '#',
          parentId: 3,
          img: '../../../assets/dining_coming.png',
        },
      ],
    },
  ];

  hotelOfferSelected: any = this.hotelOffers[0];
  dineOfferSelected: any = this.dineOffers[0];

  constructor() { }

  ngOnInit() {
  }

  hotelOfferChanged(event: any) {
    const id = event.target.value;
    const index = this.hotelOffers.findIndex(offer => offer.id == id);
    const indexDine = this.dineOffers.findIndex(offer => offer.id == id);
    this.hotelOfferSelected = this.hotelOffers[index];
    this.dineOfferSelected = this.dineOffers[indexDine];
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

}
