<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
    <div class="inner_modal welcome_bg">
      <div class="box_dialog full-width">
        <div class="box_dialog_content">
          <h1>Your Journey of<br/>
            Abundance Begins Here</h1>
            <hr class="line_top">
          <p>Collect <b>36 Ang Pao</b> during the giveaway period for a chance to win Grand Prizes, with daily surprises of additional rewards waiting for you.</p>
          <img src="../../../assets/welcome_assets.png" alt="" class="prize">
          <div class="prize_info">
            <div class="item_welcome">
              <div class="content">
                <span>1</span>
                <h4>Click to collect 2 pop-up <b>Ang Pao</b> daily.</h4>
              </div>
              <img src="../../../assets/item_welcome1.png">
            </div>
            <hr/>
            <div class="item_welcome">
              <div class="content">
                <span>2</span>
                <h4>Stand a chance to win surprise reward while you collect Ang Pao daily.</h4>
              </div>
              <img class="large_img" src="../../../assets/item_welcome2.png">
            </div>
          </div>
          <!-- <button mat-dialog-close>Register</button> -->
          <!-- <button (click)="register()">Register</button> -->
          <!-- <div *ngIf="!isLoggedIn" class="link_inline">Already have an account? <a (click)="login()" class="cursor-pointer">Log In</a></div> -->
          <img src="../../../assets/frame_bottom.png" class="bootom_frame" alt="">
        </div>
      </div>
    </div>
    <img src="../../../assets/frame_welcome.png" alt="" class="frame_welcome">
  </div>
