<div class="wrapper_loading" *ngIf="isLoading">
  <div class="loading_box">
    <img src="../../../assets/logo_master.png" alt="" [ngsReveal]="{origin: 'top', scale: 1, distance: '10px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
    <img class="bg_loading" src="../../../assets/bg_loading.jpg" alt="" [ngsReveal]="{origin: 'bottom', scale: 1, distance: '10px', easing: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)' }">
    <!-- <div class="loading-text app-loading">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div> -->
    <div class="loading_progress">
      <div class="loadingbar">
        <div class="loading_inner"></div>
      </div>
      <h4>Loading...</h4>
    </div>
  </div>
</div>
<div class="body_container">
  <ngx-spinner type="square-jelly-box" bdColor="#cd3b33c7"></ngx-spinner>
  <router-outlet></router-outlet>
</div>
