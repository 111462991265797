import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss']
})
export class LoadingPopupComponent implements OnInit {

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<LoadingPopupComponent>
  ) { }

  ngOnInit() {
  }

}
