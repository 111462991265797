
  <span class="badge_menu">Account</span>
  <div class="avatar">
    <img src="{{profile.avatar}}">
    <h4>{{profile.name}}</h4>
  </div>
  <div class="info_user">
    <svg xmlns="http://www.w3.org/2000/svg" width="54.892" height="41.131" viewBox="0 0 54.892 41.131">
      <g id="Group_22105" data-name="Group 22105" transform="translate(1028.052 -4029.725)">
        <path id="Path_66037" data-name="Path 66037" d="M-1028.052,4066.478v-25.191a1.584,1.584,0,0,1,.239.1q11.139,7.426,22.277,14.857a8.265,8.265,0,0,0,9.812.029q11.01-7.33,22.011-14.672c.156-.1.317-.2.544-.346v.436q0,12-.007,24.008a5.466,5.466,0,0,1-.16,1.324,5.054,5.054,0,0,1-5.165,3.824q-10.959.006-21.918,0-11.146,0-22.293,0a5.281,5.281,0,0,1-3.8-1.44A5.676,5.676,0,0,1-1028.052,4066.478Z" fill="#fff"/>
        <path id="Path_66038" data-name="Path 66038" d="M-1028.052,4034.1c.088-.306.165-.615.267-.917a5.074,5.074,0,0,1,4.645-3.45c.178-.009.357-.008.536-.008q22,0,44,0a5.255,5.255,0,0,1,4.018,1.566,4.883,4.883,0,0,1-.786,7.543c-3.324,2.254-6.678,4.464-10.02,6.692q-5.992,4-11.986,7.991a5.106,5.106,0,0,1-6.452,0c-7.041-4.693-14.068-9.407-21.133-14.063a6.21,6.21,0,0,1-3.087-3.956Z" fill="#fff"/>
      </g>
    </svg>
    <h4>{{ profile.username }}</h4>
  </div>
  <div class="info_user">
    <svg xmlns="http://www.w3.org/2000/svg" width="51.285" height="51.292" viewBox="0 0 51.285 51.292">
      <g id="Group_22107" data-name="Group 22107" transform="translate(1026.248 -4147.449)">
        <path id="Path_66039" data-name="Path 66039" d="M-1012.229,4147.449a4.623,4.623,0,0,1,2.45,1.251,4.163,4.163,0,0,1,1.167,2.848,31.686,31.686,0,0,0,.79,6.694c.225.922.51,1.829.738,2.751a4.13,4.13,0,0,1-.863,3.7c-1.444,1.857-2.85,3.744-4.256,5.629a.543.543,0,0,0-.024.477,30.954,30.954,0,0,0,10.506,11.85c1.092.705,2.223,1.348,3.326,2.035a.5.5,0,0,0,.661-.053q2.859-2.194,5.739-4.36a4.011,4.011,0,0,1,3.419-.691c1.477.335,2.929.8,4.418,1.062a25.448,25.448,0,0,0,4.375.427c2.351,0,4.254,1.016,4.82,3.646v10.419a.438.438,0,0,0-.067.131,4.158,4.158,0,0,1-4.3,3.477,49.693,49.693,0,0,1-9.878-1.107,46.518,46.518,0,0,1-16.294-7.017,46.98,46.98,0,0,1-14.708-15.992,46.7,46.7,0,0,1-6.039-22.962,4.12,4.12,0,0,1,.961-2.724,4.6,4.6,0,0,1,2.64-1.49Z" fill="#fff"/>
      </g>
    </svg>
    <h4>{{ profile.phone }}</h4>
  </div>
  <div class="info_user">
    <svg xmlns="http://www.w3.org/2000/svg" width="43.567" height="58.09" viewBox="0 0 43.567 58.09">
      <g id="Group_22109" data-name="Group 22109" transform="translate(1022.389 -4262.088)">
        <path id="Path_66040" data-name="Path 66040" d="M-1001.054,4320.178a16.017,16.017,0,0,1-1.422-1.087,81.576,81.576,0,0,1-14.363-17.667,41.4,41.4,0,0,1-5.034-12.433,22.113,22.113,0,0,1,12.529-25.038,21.638,21.638,0,0,1,25.737,6.394c4.571,5.868,5.738,12.471,4.049,19.638a43.062,43.062,0,0,1-5.773,13.039,83.46,83.46,0,0,1-13.392,16.07,15.963,15.963,0,0,1-1.424,1.084Zm.809-24.2a12.134,12.134,0,0,0,11.74-12.483,12.132,12.132,0,0,0-12.451-11.714,12.134,12.134,0,0,0-11.741,12.482A12.137,12.137,0,0,0-1000.245,4295.978Z" fill="#fff"/>
      </g>
    </svg>
    <h4>{{ profile.state }}</h4>
  </div>
  <a class="logout_buton" style="margin: 1px;width: 100%;" (click)="logout()">Log Out</a>
  <button class="clean_btn" (click)="back()">Back</button>