<div class="section_title" id="#hotel">
  <img src="../../../assets/openhouse.png">
  <p>Celebrate the Year of the Dragon at Plenitude Property open houses with a variety of captivating activities! Bring your loved ones along for a fun-filled day!</p>
  <img src="../../../assets/Locations.png" class="location_title">
</div>

<owl-carousel-o [options]="customOptions">
  <ng-template carouselSlide *ngFor="let item of openHouseData.event">
    <div class="slider_items large_slider framed">
      <img [src]="item.image" class="loc_imh">
      <h3>{{ item.name }}</h3>
      <p>{{ item.locations }}</p>

      <div class="loc">
        <!-- <a [href]="generateWazeLink(item.ll)" target="_blank"><img src="../../../assets/waze.png"></a> -->
        <a [href]="item.waze" target="_blank"><img src="../../../assets/waze.png"></a>
        <a [href]="item.map" target="_blank"><img src="../../../assets/map.png"></a>
      </div>

      <div class="informatio bg_highlight">
        <h4>Event Date</h4>
        <!-- <h2>{{ moment(item.date).format('D MMM YY') }}</h2> -->
        <h1>{{ item.dateString }}</h1>
        <hr/>
        <h4>Time</h4>
        <!-- <h2>{{ moment(item.date).format('D MMM YY') }}</h2> -->
        <h1>{{item.time}}</h1>
      </div>

      <div class="informatio flexed">
        <div class="item_number cursor-pointer" (click)="sendToWhatsApp(item, item.contactNumber)">
          <img src="../../../assets/wa.png">
          <h2>{{ item.contactNumber}}</h2>
        </div>
        <div class="item_number cursor-pointer" (click)="sendToWhatsApp(item, item.contactNumber2)">
          <img src="../../../assets/wa.png">
          <h2>{{ item.contactNumber2}}</h2>
        </div>
      </div>

      <button (click)="rsvp(item.name)">RSVP</button>
      <img class="openhouse_bg" src="../../../assets/openhouse_frame.png">
    </div>
  </ng-template>
</owl-carousel-o>

<div class="scrolldown">
  <!-- <img src="../../../assets/scroll_light.png" class="animate mobile_view"> -->
  <img src="../../../assets/scroll.png" class="animate">
</div>
