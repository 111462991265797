import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {NgsRevealModule} from 'ngx-scrollreveal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DragScrollModule } from 'ngx-drag-scroll';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { MatDialogModule, MatSnackBarModule } from '@angular/material';
import { RsvpFormComponent } from './pages/open-house/rsvp-form/rsvp-form.component';
import { RsvpFormSubmitedComponent } from './pages/open-house/rsvp-form-submited/rsvp-form-submited.component';
import { PrizeClaimComponent } from './pages/prize-claim/prize-claim.component';
import { PrizeClaimSubmitedComponent } from './pages/prize-claim/prize-claim-submited/prize-claim-submited.component';
import { RegisterSubmitedComponent } from './pages/register/register-submited/register-submited.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllowNumberOnlyDirective } from './utils/allow-number-only.directive';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { PrizeComponent } from './pages/prize/prize.component';
import { OpenHouseComponent } from './pages/open-house/open-house.component';
import { HotelOfferComponent } from './pages/hotel-offer/hotel-offer.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './utils/jwt-interceptor';
import { LoginSucceedComponent } from './pages/login/login-succeed/login-succeed.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { ForgotSucceedComponent } from './pages/forgot/forgot-succeed/forgot-succeed.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { UpdatePasswordSubmitedComponent } from './pages/update-password/update-password-submited/update-password-submited.component';
import { TncComponent } from './pages/tnc/tnc.component';
import { LoadingPopupComponent } from './component/loading-popup/loading-popup.component';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    RsvpFormComponent,
    RsvpFormSubmitedComponent,
    PrizeClaimComponent,
    PrizeClaimSubmitedComponent,
    RegisterSubmitedComponent,
    AllowNumberOnlyDirective,
    WelcomeComponent,
    PrizeComponent,
    OpenHouseComponent,
    HotelOfferComponent,
    VerifyEmailComponent,
    LoginSucceedComponent,
    ProfileComponent,
    FooterComponent,
    ForgotComponent,
    ForgotSucceedComponent,
    UpdatePasswordComponent,
    UpdatePasswordSubmitedComponent,
    TncComponent,
    LoadingPopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragScrollModule,
    NgsRevealModule,
    AppRoutingModule,
    CarouselModule,
    NgxSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    HttpClientModule,
    NgxMaskModule.forRoot({showMaskTyped: true})
  ],
  entryComponents: [
    LoadingPopupComponent,
    RegisterComponent,
    RegisterSubmitedComponent,
    LoginComponent,
    PrizeClaimComponent,
    PrizeClaimSubmitedComponent,
    RsvpFormComponent,
    RsvpFormSubmitedComponent,
    WelcomeComponent,
    PrizeComponent,
    LoginSucceedComponent,
    ForgotComponent,
    ForgotSucceedComponent,
    UpdatePasswordComponent,
    UpdatePasswordSubmitedComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
