import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, api } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class OpenHouseService {

  constructor(private _httpClient: HttpClient) {}
  
  attend(params: any): Observable<ApiResponse> {
      return this._httpClient.post<ApiResponse>(
          api.openHouse.attend,
          params
      );
  }
}
