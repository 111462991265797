<div class="section_title">
  <img class="mobile_view" src="../../../assets/title_hotel_offers.png">
  <img class="desktop_view" src="../../../assets/title_hotel_offers_desktop.png">
  <!-- <div class="badge_date">
    <p>Promotion Period</p>
    <h4>20 January - 27 February 2024</h4>
  </div> -->
</div>

<div class="location_select">
  <h4>Locations</h4>
  <div class="select_frame">
    <select name="" id="" (change)="hotelOfferChanged($event)">
      <!-- <option value="" selected disabled>Location</option> -->
      <option value="penang" class="dark_option"  *ngFor="let item of hotelOffers" [value]="item.id">{{ item.location }}</option>
    </select>
  </div>
</div>

<img src="../../../assets/stay.png" alt="" class="subheader_img">
<owl-carousel-o [options]="customOptions">
  <ng-template carouselSlide *ngFor="let item of hotelOfferSelected.offers">
    <div class="slider_items clear_style">
      <!-- <h3>{{ item.name }}</h3> -->
      <!-- <span style="color: white;padding: 16px 8px;">Discount {{ item.discount }}%</span> -->
      <!-- <div class="section_content_stay">
        <div class="inner">
          <h2>{{item.name}}</h2>
          <h1><span>from</span>RM360+</h1>
          <hr>
          <p>Book & Stay Period</p>
          <h4>1 Jan - 29 Feb 2024</h4>
        </div>
      </div> -->
      <a class="clik_area" (click)="openUrl(item.url)"></a>
      <img src={{item.img}}>
    </div>
  </ng-template>
</owl-carousel-o>

<!-- <hr class="light_sparator" /> -->
<br/><br/><br/>




<img src="../../../assets/dinning.png" alt="" class="subheader_img">

<div class="fix_width_desktop">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide *ngFor="let item of dineOfferSelected.offers">
      <div class="slider_items clear_style">
        <!-- <div class="section_content_stay deals">
          <div class="inner">
            <h2>{{item.name}}</h2>
            <h1><span>from</span>RM360+</h1>
            <hr>
            <p>Book & Stay Period</p>
            <h4>1 Jan - 29 Feb 2024</h4>
            <button (click)="openUrl(item.url)">BOOK</button>
          </div>
        </div> -->
        <a class="clik_area" (click)="openUrl(item.url)"></a>
        <img src={{item.img}}>

        <!-- <h3>{{ item.name }}</h3> -->
        <!-- <span style="color: white;padding: 16px 8px;">Discount {{ item.discount }}%</span> -->
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

