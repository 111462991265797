<div class="dialog_modal" [@dialogEnter] [@dialogExit]>
  <div class="inner_modal login_modal">
    <div class="close_button" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="57.624" height="57.624" viewBox="0 0 57.624 57.624">
        <g id="Group_22085" data-name="Group 22085" transform="translate(-963.123 -61.188)">
          <rect id="Rectangle_6614" data-name="Rectangle 6614" width="73.192" height="8.3" rx="4.15" transform="translate(968.992 61.188) rotate(45)" fill="#fff"/>
          <rect id="Rectangle_6615" data-name="Rectangle 6615" width="73.192" height="8.3" rx="4.15" transform="translate(963.123 112.943) rotate(-45)" fill="#fff"/>
        </g>
      </svg>
    </div>
    <div class="box_dialog ">
      <div class="box_dialog_content box_forgot">
        <h1>FORGOT<br/>PASSWORD</h1>
        <div class="form">
          <form [formGroup]="dataForm">
            <input type="text" placeholder="Email Address" formControlName="email">
          </form>
        </div>
        <br/><br/>
        <button (click)="reset()" [ngClass]="{'disable_button': !dataForm.valid}" [disabled]="!dataForm.valid">Reset Password</button>
        <div class="link_inline"><a class="cursor-pointer" (click)="login()">Back to Login</a></div>
      </div>
    </div>
  </div>
  <img src="../../../assets/login_frame.png" class="login_frame" alt="">
</div>
