import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-succeed',
  templateUrl: './forgot-succeed.component.html',
  styleUrls: ['./forgot-succeed.component.scss']
})
export class ForgotSucceedComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ForgotSucceedComponent>
  ) { }

  ngOnInit() {
  }

  close() {
    this._dialogRef.close();
  }

}
