import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { RegisterComponent } from '../register/register.component';
import { ForgotComponent } from '../forgot/forgot.component';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services';
import { ApiResponse, DefaultValue } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { LoginSucceedComponent } from './login-succeed/login-succeed.component';
import { LoadingPopupComponent } from '../../component/loading-popup/loading-popup.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('dialogEnter', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
      transition(':enter', [
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('dialogExit', [
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private _spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<LoginComponent>
  ) { }

  ngOnInit() {
  }

  login() {
    // this._spinner.show();
    this._dialog.open(LoadingPopupComponent);

    const form = this.loginForm.value;
    const params: any = {
      email: form.email,
      password: form.password,
    }

    this._authService.login(params).subscribe((response: ApiResponse) => {
      const user = response.data;
      localStorage.setItem(environment.authIdentifierKey, user.token);
      delete user.token;
      localStorage.setItem(environment.authUser, JSON.stringify(user));

      setTimeout(() => {
        // this._spinner.hide();
        this._dialog.closeAll();
        this._dialogRef.close()
        this._dialog.open(LoginSucceedComponent, {data: user})
      }, DefaultValue.loadingTimeOut);
    }, (error) => {
      this._snackBar.open("Oops.. !" + error.error.message, 'Close');
      this._dialog.closeAll();
      // this._dialog.open(LoginComponent);
      // this._spinner.hide();
    });
  }

  register() {
    this._dialogRef.close();
    const dialog = this._dialog.open(RegisterComponent, {
      width: '100vw',
    });
  }

  forgot() {
    this._dialogRef.close();
    const dialog = this._dialog.open(ForgotComponent, {
      width: '100vw',
    });
  }

  close() {
    this._dialogRef.close();
  }

}
