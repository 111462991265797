import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RsvpFormComponent } from './rsvp-form/rsvp-form.component';
import { Helper } from 'src/app/utils/helper';

@Component({
  selector: 'app-open-house',
  templateUrl: './open-house.component.html',
  styleUrls: ['./open-house.component.scss']
})
export class OpenHouseComponent implements OnInit {
  moment = moment;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    // autoWidth:true,
    center: true,
    margin: 0,
    stagePadding: 40,
    navText: ['<', '>'],
    autoplay: true,
    rewind: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      768: {
        items: 1
      },
      940: {
        stagePadding:495,
        items: 1,
      }
    },
    nav: false,
  }

  openHouseData: any = {
    highlights: [
      {
        name: "Lion Dance Peformance",
        image: "../../../assets/e1.png"
      },
      {
        name: "Yee Sang Tossing",
        image: "../../../assets/e2.png"
      },
      {
        name: "God of Prosperity Appearence",
        image: "../../../assets/e3.png"
      },
      {
        name: "Flower Balloon DIY Workshop",
        image: "../../../assets/e4.png"
      },
      {
        name: "Food Trucks",
        image: "../../../assets/e5.png"
      },
      {
        name: "Ice Cream Give Away",
        image: "../../../assets/e6.png"
      }
    ],
    event: [
      {
        id: 1,
        image: "../../../assets/bukit-bintang.png",
        name: 'Bukit Bintang Sales Gallery',
        attending: 'Bukit Bintang Sales Gallery @ Sungai Petani, Kedah',
        date: '2024-02-13',
        dateString: '13 Feb 2024',
        contactNumber: '+6012 586 6269',
        contactNumber2: '+6012 780 7221',
        map: "https://maps.app.goo.gl/yprctJjxpWZJBoRo9",
        waze: "https://waze.com/ul/hw0zrzqgvh",
        ll: '5.6192931244960596, 100.51902721294519',
        locations: 'Sungai Petani, Kedah',
        time: '10 AM - 4 PM'
      },
      {
        id: 2,
        image: "../../../assets/desa-tebrau.png",
        name: 'Desa Tebrau Sales Gallery',
        attending: 'Desa Tebrau Sales Gallery @ Johor Bahru, Johor',
        date: '2024-02-01',
        dateString: '24 - 25 Feb 2024',
        contactNumber: '+6012 702 6033',
        contactNumber2: '+6014 337 1230',
        map: "https://maps.app.goo.gl/AqS6iF2F9W6J73fy6",
        waze: "https://waze.com/ul/hw23bcg35e",
        ll: '1.565263804929603, 103.79146010689486',
        locations: 'Johor Bahru, Johor',
        time: '10 AM - 5 PM'
      },
      {
        id: 3,
        image: "../../../assets/impian-hills.png",
        name: 'Impian Hills Sales Gallery',
        attending: 'Impian Hills Sales Gallery @ Ulu Tiram, Johor',
        date: '2024-03-02',
        dateString: '2 Mar 2024',
        contactNumber: '+6018 370 0318',
        contactNumber2: '+6011 1166 8088',
        map: "https://maps.app.goo.gl/ZoEJQBCzhmgWj1SX8?g_st=ic",
        waze: "https://waze.com/ul/hw23c62r1k",
        ll: '1.6275411361883743, 103.81971623121969',
        locations: 'Ulu Tiram, Johor',
        time: '10 AM - 4 PM'
      }
    ]
  };

  constructor(
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  generateWazeLink(ll: string) {
    return Helper.generateWazeLink(ll);
  }

  sendToWhatsApp(event: any, contact: any): void {
    let message = 'Hello, I would like to receive more detailed information about the open house at ' + event.name + ' ' + event.locations + '. Thank you!';
    window.open(Helper.generateWhatsAppMessageLink(contact.replaceAll(" ",""), message), '_blank');
  }

  rsvp(name: any) {
    this._dialog.open(RsvpFormComponent, {data: {info: this.openHouseData, name:name }})
  }

}
